import Link from "next/link";
import Image from "next/image";

import { Button } from "@/components/ui/button";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";

const navLinks = [
  {
    label: "Home",
    href: "/",
  },
  {
    label: "Free Masterclasses",
    href: "/masterclasses",
  },
  {
    label: "Explore Bootcamps",
    href: "/bootcamps",
  },
  {
    label: "Our Experts",
    href: "/teachers",
  },
  {
    label: "Tradewise Gold",
    href: "/gold",
  },
];

const NotFound = () => {
  return (
    <div className="overflow-hidden">
      <nav className="hidden md:flex w-full mt-2 container items-center justify-between sm:px-4 md:px-6">
        <div className="flex items-center gap-1.5 h-full">
          <Link href="/">
            <Image
              src="/logo.svg"
              alt="TradwiseApp Logo"
              width={110}
              height={110}
            />
          </Link>
        </div>
        <ul className="w-full flex justify-center gap-3 items-center max-md:hidden">
          {navLinks.map((link) => {
            return (
              <li key={link.href}>
                <Link
                  href={link.href}
                  className="text-foreground px-3 hover:[text-decoration:none] grid place-items-center h-full group transition-all"
                >
                  {link.label}
                  <div className="h-[1.5px] rounded-md bg-black w-full scale-x-0 group-hover:scale-x-100 origin-left transition-all ease-linear duration-300" />
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
      <main className="container flex flex-col justify-center h-[70vh]">
        <DotLottieReact
          src="404.lottie"
          loop
          autoplay
          className="w-full h-[450px]"
        />
        <Link href="/" className="mx-auto -mt-20 max-md:-mt-32">
          <Button className="px-6 py-3">Back to the home page</Button>
        </Link>
      </main>
    </div>
  );
};

export default NotFound;
